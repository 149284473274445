import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Cookies } from "react-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import Lottie from "react-lottie";

import AuthContext from "./AuthContext";

import { setUserData, userLoggedOut } from "./userSlice";

import {
  useGetSessionMutation,
  useLoginMutation,
  useLogoutMutation,
  useResetPasswordMutation,
  useConfirmPasswordResetMutation,
} from "api/loginAPI";
import { useGetUserMutation } from "api/userAPI";

import * as animationData from "./cookingLoading.json";

const cookies = new Cookies();

const defaultAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const [token, setToken] = useState(null);

  const [waitAuthCheck, setWaitAuthCheck] = useState(true);

  const [getUser] = useGetUserMutation();

  const [getSession] = useGetSessionMutation();

  const [loginUser] = useLoginMutation();

  const [logoutUser] = useLogoutMutation();

  const [resetPassword] = useResetPasswordMutation();

  const [confirmResetPassword] = useConfirmPasswordResetMutation();

  useEffect(() => {
    async function sessionCheck() {
      const userRes = await sessionIDCheck();
      return userRes;
    }
    sessionCheck().then((result) => {
      if (result?.data?.userID) {
        setToken(result?.data?.userID);

        let origin = location?.pathname || "/home";

        if (origin === "/login") {
          origin = "/home";
        }

        setWaitAuthCheck(false);

        navigate(origin);
      } else {
        setWaitAuthCheck(false);

        navigate("/login");
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function sessionIDCheck() {
    //get session-id cookie
    const sessionID = cookies.get("session-id");

    if (typeof sessionID === "undefined") {
      return "No Active Session";
    } else {
      //check if session-id is valid. If yes, return userID asscociated with session-id. If no, return error
      try {
        const res = await getSession({ sessionID });

        //If session-id is valid and userID is returned, get the user info with the returned userID.
        if (res.data.status === true && res.data.isActive) {
          const user = await getUser(res.data.userID);

          dispatch(setUserData(user.data));

          return user;
        }
      } catch (e) {
        console.log("🚀 ~ newPromise ~ e", e);
      }
    }
  }

  const handleLogin = async (model) => {
    try {
      const res = await loginUser({
        email: model.email,
        password: model.password,
      });

      if (res.error) {
        throw res.error;
      }

      if (res?.data?.status === true) {
        const user = await getUser(res.data.userID);

        dispatch(setUserData(user.data));

        setToken(user?.data?.userID);

        let origin = location?.pathname || "/home";

        if (origin === "/login") {
          origin = "/home";
        }

        navigate(origin);
      } else {
      }
    } catch (e) {
      throw e;
    }
  };

  const handleLogout = async (user) => {
    setToken(null);
    const res = await logoutUser({ userID: user.userID });

    if (res.data.status === true) {
      dispatch(userLoggedOut());

      setToken(null);
    }
  };

  const handleResetPassword = async (model) => {
    const resetBody = {
      email: model.email,
    };

    const res = await resetPassword(resetBody);

    if (res.error) {
      throw res.error;
    }

    if (res.data.status === true && res.data.codeSent === true) {
      return res.data;
    }
  };

  const handleConfirmResetPassword = async (model, generatedID) => {
    const confirmPasswordResetBody = {
      email: model.email,
      password: model.password,
      passwordResetCode: model.code,
      generatedID: generatedID,
    };

    const res = await confirmResetPassword(confirmPasswordResetBody);

    if (res.error) {
      throw res.error;
    }

    if (res.data.status === true) {
      navigate("/login");
    }
  };

  const value = {
    token,
    onLogin: handleLogin,
    onLogout: handleLogout,
    resetPassword: handleResetPassword,
    confirmResetPassword: handleConfirmResetPassword,
  };

  return waitAuthCheck ? (
    <div className="min-h-screen bg-cyan-200 flex flex-col justify-center content-center">
      <Lottie
        options={defaultAnimationOptions}
        height={"30%"}
        width={"30%"}
        isClickToPauseDisabled={true}
      />
      <h2 className="mt-2 text-center text-3xl font-extralight text-black">
        Loading...
      </h2>
    </div>
  ) : (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
};
