import { Link, useNavigate } from "react-router-dom";

import { Fragment, useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";

import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon, ChevronDownIcon } from "@heroicons/react/outline";
import {
  ClockIcon,
  ScaleIcon,
  CheckIcon,
  PlusIcon,
} from "@heroicons/react/solid";
import {
  useCreateRecipeFromManualMutation,
  useRequestSignedURLMutation,
} from "api/recipeAPI";

const navigation = [{ name: "Home", href: "/home", current: false }];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ManualAddRecipe() {
  let navigate = useNavigate();

  const user = useSelector((state) => state.user);

  const inputFileRef = useRef();

  const [createRecipeFromManual, { isLoading: isCreatingRecipe }] =
    useCreateRecipeFromManualMutation();

  const [requestSignedURL, { isLoading: isRequestingSignedURL }] =
    useRequestSignedURLMutation();

  const [loading] = useState(false);

  const [addRecipe, setAddRecipe] = useState({
    groupID: user.groupID,
    recipeName: "",
    cookTime: 0,
    categories: "",
    cuisines: "",
    imageUrls: [],
    keywords: [],
    ingredients: [],
    instructions: [],
    servings: 0,
    fileName: null,
    file: null,
  });

  const [selectedFile, setSelectedFile] = useState();

  const [preview, setPreview] = useState();

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);

    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };

  async function onChangeRecipe(key, value) {
    setAddRecipe({ ...addRecipe, [key]: value });
  }

  async function onChangeRecipeArray(key, value, index) {
    let tmpArr = [...addRecipe[key]];

    tmpArr[index] = value;

    setAddRecipe({ ...addRecipe, [key]: tmpArr });
  }

  return (
    <>
      <div className="min-h-full">
        <Disclosure
          as="nav"
          className="bg-cyan-200 border-b border-indigo-300 border-opacity-25 lg:border-none"
        >
          {({ open }) => (
            <>
              <div className="mx-auto px-2 sm:px-4 lg:px-8">
                <div className="relative h-16 flex items-center justify-between lg:border-b lg:border-indigo-400 lg:border-opacity-25">
                  <div className="flex-1 px-2 flex justify-center lg:ml-6 lg:justify-end">
                    <div className="hidden lg:block lg:ml-10">
                      <div className="flex space-x-4">
                        {navigation.map((item) => (
                          <Link
                            key={item.name}
                            className={classNames(
                              item.current
                                ? "bg-indigo-700 text-white"
                                : "text-gray-700 hover:bg-gray-800 hover:text-white hover:bg-opacity-75",
                              "rounded-md py-2 px-3 text-sm font-medium"
                            )}
                            to={item.href}
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                    <div className="w-full"></div>
                  </div>
                  <div className="flex lg:hidden">
                    <Disclosure.Button className="bg-cyan-600 p-2 rounded-md inline-flex items-center justify-center text-indigo-200 hover:text-white hover:bg-cyan-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-offset-transparent focus:ring-transparent">
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="hidden lg:block"></div>

                  <div className="hidden lg:block lg:ml-4">
                    <div className="flex items-center">
                      <Menu as="div" className="ml-3 relative flex-shrink-0">
                        <div>
                          <Menu.Button className="bg-cyan-200 flex text-sm items-center text-white focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-offset-transparent focus:ring-transparent">
                            <div className="">
                              <div className="text-sm font-medium text-gray-700">
                                {user.fullName}
                              </div>
                              <div className="text-xs font-medium text-gray-500">
                                {user.email}
                              </div>
                            </div>
                            <ChevronDownIcon
                              className={classNames(
                                "text-gray-400",
                                "ml-2 h-5 w-5 group-hover:text-gray-500"
                              )}
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block py-2 px-4 text-sm text-gray-700"
                                  )}
                                  onClick={async () => {
                                    // await onLogout(user);
                                  }}
                                >
                                  Log Out
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="lg:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 md:flex md:flex-col md:justify-center md:items-center">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current
                          ? "bg-indigo-700 text-white"
                          : "text-gray-700 hover:bg-indigo-500 hover:bg-opacity-75",
                        "flex justify-center rounded-md py-2 px-3 text-base font-medium"
                      )}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
                <div className="pt-4 pb-3 border-t border-indigo-700">
                  <div className="px-5 flex items-center">
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-700">
                        {user.fullName}
                      </div>
                      <div className="text-sm font-medium text-gray-500">
                        {user.email}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    <Disclosure.Button
                      className="block rounded-md py-2 px-3 text-base font-medium text-gray-700 hover:bg-indigo-500 hover:bg-opacity-75"
                      onClick={async () => {
                        // await onLogout(user);
                      }}
                    >
                      Log Out
                    </Disclosure.Button>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <main className="lg:mt-16">
          {loading || isCreatingRecipe || isRequestingSignedURL ? (
            <div className="mx-auto sm:px-6 lg:px-8 mb-8">
              <div className="bg-white lg:border animate-pulse lg:rounded-lg lg:shadow px-5 py-6 sm:px-6">
                <div className="flex flex-col">
                  <div className="flex w-full">
                    <div className="w-1/3">
                      <div className="h-32 w-32 bg-slate-700 rounded-lg"></div>
                    </div>

                    <div className="flex flex-col w-2/3 lg:ml-16 ml-8">
                      <div className="h-2 bg-slate-700 rounded mt-8"></div>
                      <div className="flex mt-4">
                        <div className="flex items-center mr-2">
                          <ClockIcon
                            className="w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <div className="h-2 w-16 lg:w-32 bg-slate-700 rounded lg:ml-3"></div>
                        </div>
                        <div className="flex items-center ml-2">
                          <ScaleIcon
                            className="w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <div className="h-2 w-16 lg:w-32 bg-slate-700 rounded lg:ml-3"></div>
                        </div>
                      </div>
                      <div className="flex mt-4"></div>
                    </div>
                  </div>

                  <div className="lg:mt-16 mt-8 pb-8">
                    <div className="max-w-3xl mx-auto lg:max-w-7xl">
                      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
                        <div className="grid grid-cols-1 gap-4">
                          <div className="pb-4">
                            <h3 className="text-lg lg:text-2xl text-center leading-6 font-medium lg:font-bold text-gray-900 ">
                              Ingredients
                            </h3>
                          </div>
                          <ul className="divide-y divide-gray-200">
                            <li
                              key={1}
                              className="px-4 py-4 sm:px-0 font-light"
                            >
                              <div className="h-2 w-full bg-slate-700 rounded"></div>
                            </li>
                            <li
                              key={2}
                              className="px-4 py-4 sm:px-0 font-light"
                            >
                              <div className="h-2 w-full bg-slate-700 rounded"></div>
                            </li>
                            <li
                              key={3}
                              className="px-4 py-4 sm:px-0 font-light"
                            >
                              <div className="h-2 w-full bg-slate-700 rounded"></div>
                            </li>
                          </ul>
                        </div>

                        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                          <div className="pb-4">
                            <h3 className="text-lg lg:text-2xl text-center leading-6 font-medium lg:font-bold text-gray-900">
                              Instructions
                            </h3>
                          </div>
                          <ul className="">
                            <li
                              key={1}
                              className="flex items-center px-4 py-4 sm:px-0 font-light"
                            >
                              <div className="h-2 w-full bg-slate-700 rounded lg:ml-3"></div>
                            </li>

                            <li
                              key={2}
                              className="flex items-center px-4 py-4 sm:px-0 font-light"
                            >
                              <div className="h-2 w-full bg-slate-700 rounded lg:ml-3"></div>
                            </li>

                            <li
                              key={3}
                              className="flex items-center px-4 py-4 sm:px-0 font-light"
                            >
                              <div className="h-2 w-full bg-slate-700 rounded lg:ml-3"></div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mx-auto sm:px-6 lg:px-8">
              <div className="bg-white lg:rounded-lg lg:border lg:mb-16 lg:shadow px-5 py-6 sm:px-6">
                <div className="flex flex-col">
                  <div className="flex w-full">
                    <div className="w-1/3">
                      {selectedFile ? (
                        <button
                          type="button"
                          className="border-2 border-gray-300 border-dashed rounded-lg"
                          onClick={() => {
                            var elem = document.getElementById("fileDialog");
                            if (elem && document.createEvent) {
                              var evt = document.createEvent("MouseEvents");
                              evt.initEvent("click", true, false);
                              elem.dispatchEvent(evt);
                            }
                          }}
                        >
                          <img
                            className="lg:h-32 lg:w-32 rounded-lg"
                            src={preview}
                            alt=""
                          />
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => {
                            var elem = document.getElementById("fileDialog");
                            if (elem && document.createEvent) {
                              var evt = document.createEvent("MouseEvents");
                              evt.initEvent("click", true, false);
                              elem.dispatchEvent(evt);
                            }
                          }}
                        >
                          <span className="mt-2 block text-sm font-medium text-gray-900">
                            Add an image!
                          </span>
                        </button>
                      )}

                      <input
                        ref={inputFileRef}
                        id="fileDialog"
                        style={{ display: "none" }}
                        type="file"
                        accept="image/*"
                        onChange={(event) => onSelectFile(event)}
                      />
                    </div>

                    <div className="flex flex-col w-2/3 lg:ml-16 ml-8">
                      <div>
                        <input
                          type="text"
                          name="recipeName"
                          id="recipeName"
                          className="shadow-sm text-xs lg:text-3xl font-semibold text-gray-800 tracking-wide w-full border-gray-300 rounded-md"
                          placeholder={addRecipe.recipeName}
                          value={addRecipe.recipeName}
                          onChange={(event) => {
                            onChangeRecipe("recipeName", event.target.value);
                          }}
                        />
                      </div>

                      <div className="flex flex-col lg:flex-row mt-4">
                        <div className="flex items-center md:mr-2">
                          <ClockIcon
                            className="hidden lg:block w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="flex flex-row items-center lg:ml-3">
                            <input
                              type="text"
                              name="cookTime"
                              id="cookTime"
                              className="text-xs md:text-base shadow-sm text-gray-800 border-gray-300 rounded-md"
                              placeholder={Math.floor(
                                addRecipe.cookTime / 60000000
                              )}
                              value={Math.floor(addRecipe.cookTime / 60000000)}
                              onChange={(event) => {
                                onChangeRecipe(
                                  "cookTime",
                                  event.target.value * 60000000
                                );
                              }}
                            />
                            <p className="ml-3">Minutes</p>
                          </span>
                        </div>
                        <div className="flex items-center lg:ml-2">
                          <ScaleIcon
                            className="hidden lg:block w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="flex flex-row items-center mt-4 lg:mt-0 lg:ml-3">
                            <input
                              type="text"
                              name="servings"
                              id="servings"
                              className="text-xs md:text-base shadow-sm text-gray-800 border-gray-300 rounded-md"
                              placeholder={addRecipe.servings}
                              value={addRecipe.servings}
                              onChange={(event) => {
                                onChangeRecipe(
                                  "servings",
                                  Number(event.target.value)
                                );
                              }}
                            />
                            <p className="ml-3">Servings</p>
                          </span>
                        </div>
                      </div>
                      <div className="flex mt-4">
                        <button
                          type="button"
                          className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-none focus:ring-offset-none focus:ring-offset-none focus:ring-none"
                          onClick={async () => {
                            const signedURLRes = await requestSignedURL({
                              fileName: selectedFile.name,
                              type: selectedFile.type,
                              file: selectedFile,
                            });

                            const res = await createRecipeFromManual({
                              recipeName: addRecipe.recipeName || "",
                              cookTime: addRecipe.cookTime || 0,
                              categories: addRecipe.categories || "",
                              cuisines: addRecipe.cuisines || "",
                              ingredients: addRecipe.ingredients || [],
                              instructions: addRecipe.instructions || [],
                              servings: addRecipe.servings || 0,
                              imageURL: signedURLRes.data.url,
                              groupID: user.groupID,
                            });

                            console.log("🚀 ~ onClick={ ~ res", res);

                            if (res.data) {
                              setAddRecipe(null);

                              navigate(`/recipe/${res.data.recipeID}`);
                            }
                          }}
                        >
                          <CheckIcon
                            className="-ml-1 mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                          <span>Add Recipe!</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="lg:mt-16 mt-8 pb-8">
                    <div className="max-w-3xl mx-auto lg:max-w-7xl">
                      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
                        <div className="grid grid-cols-1 gap-4">
                          <div className="pb-4">
                            <h3 className="text-lg lg:text-2xl text-center leading-6 font-medium lg:font-bold text-gray-900 ">
                              Ingredients
                            </h3>
                          </div>
                          <ul className="divide-y divide-gray-200">
                            {addRecipe.ingredients.map((item, index) => (
                              <li
                                key={index}
                                className="px-4 py-4 sm:px-0 font-light"
                              >
                                <input
                                  type="text"
                                  name="ingredients"
                                  id="ingredients"
                                  className="shadow-sm text-gray-800 border-gray-300 rounded-md w-full"
                                  placeholder={"Add an ingredient!"}
                                  value={item.name}
                                  onChange={(event) => {
                                    let val = {
                                      name: event.target.value,
                                      type: "default",
                                    };

                                    onChangeRecipeArray(
                                      "ingredients",
                                      val,
                                      index
                                    );
                                  }}
                                />
                              </li>
                            ))}
                          </ul>
                          <div className="flex flex-row">
                            <button
                              type="button"
                              className="inline-flex items-center w-full justify-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-transparent"
                              onClick={() => {
                                let val = {
                                  name: "",
                                  type: "default",
                                };
                                let tmpArr = [...addRecipe.ingredients];
                                tmpArr.push(val);
                                setAddRecipe({
                                  ...addRecipe,
                                  ingredients: tmpArr,
                                });
                              }}
                            >
                              <PlusIcon
                                className="-ml-0.5 mr-2 h-4 w-4"
                                aria-hidden="true"
                              />
                              Add an ingredient!
                            </button>
                          </div>
                        </div>

                        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                          <div className="pb-4">
                            <h3 className="text-lg lg:text-2xl text-center leading-6 font-medium lg:font-bold text-gray-900">
                              Instructions
                            </h3>
                          </div>
                          <ul className="">
                            {addRecipe.instructions.map((item, index) => (
                              <li
                                key={index}
                                className="flex flex-row items-center px-4 py-4 sm:px-0 font-light"
                              >
                                <div className="flex mr-8 ml-4 items-baseline lg:text-lg font-semibold text-red-600">
                                  {index + 1}
                                </div>
                                <textarea
                                  rows={4}
                                  name="comment"
                                  id="comment"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                  placeholder={"Add a Step!"}
                                  defaultValue={item.text}
                                  onChange={(event) => {
                                    let val = {
                                      name:
                                        event.target.value?.length > 44
                                          ? event.target.value.substr(
                                              0,
                                              44 - 1
                                            ) + "..."
                                          : event.target.value,
                                      text: event.target.value,
                                      type: "step",
                                    };

                                    onChangeRecipeArray(
                                      "instructions",
                                      val,
                                      index
                                    );
                                  }}
                                />
                              </li>
                            ))}
                          </ul>
                          <div className="flex flex-row">
                            <button
                              type="button"
                              className="inline-flex items-center w-full ml-16 justify-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-transparent"
                              onClick={() => {
                                let val = {
                                  name: "",
                                  text: "",
                                  type: "step",
                                };
                                let tmpArr = [...addRecipe.instructions];
                                tmpArr.push(val);
                                setAddRecipe({
                                  ...addRecipe,
                                  instructions: tmpArr,
                                });
                              }}
                            >
                              <PlusIcon
                                className="-ml-0.5 mr-2 h-4 w-4"
                                aria-hidden="true"
                              />
                              Add an ingredient!
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  );
}
