import { createSlice } from "@reduxjs/toolkit";

export const setUserData = (user) => async (dispatch, getState) => {
  /* You can redirect the logged-in user to a specific route depending on thier role */

  dispatch(setUser(user));
};

const initialState = {
  role: ["guest"], // guest
  data: {
    displayName: "John Doe",
    photoURL: "assets/images/avatars/Velazquez.jpg",
    email: "johndoe@test.com",
    shortcuts: ["calendar", "mail", "contacts", "todo"],
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
    userLoggedOut: (state, action) => initialState,
  },
  extraReducers: {},
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
