import { Fragment, useState } from "react";
import { Menu, Transition, Disclosure } from "@headlessui/react";
import {
  SearchIcon,
  PlusSmIcon,
  ChevronDownIcon,
  MenuIcon,
  XIcon,
} from "@heroicons/react/solid";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Configure,
  connectSearchBox,
  connectHits,
} from "react-instantsearch-dom";

import { Link, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";

import { useAuth } from "auth/useAuth";

import RecipeCard from "components/RecipeCard";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
);

const VirtualSB = connectSearchBox(() => <span />);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Hits = ({ hits }) => {
  return <RecipeCard recipes={hits} />;
};

const CustomHits = connectHits(Hits);

function Home() {
  const { onLogout } = useAuth();

  const user = useSelector((state) => state.user);

  let location = useLocation();

  const [searchState, setSearchState] = useState("");

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`${process.env.REACT_APP_STAGE}_recipes`}
      searchState={{
        query: searchState,
      }}
    >
      <Configure filters={`groupID:${user.groupID}`} />

      <VirtualSB />

      <>
        <div>
          <div className="flex flex-col">
            <Disclosure
              as="nav"
              className="bg-cyan-200 border-b border-indigo-300 border-opacity-25 lg:border-none"
            >
              {({ open }) => (
                <>
                  <div className="mx-auto px-2 sm:px-4 lg:px-8">
                    <div className="relative h-16 flex items-center justify-between lg:border-b lg:border-indigo-400 lg:border-opacity-25">
                      <div className="flex-1 px-2 flex justify-center lg:ml-6 lg:justify-end">
                        <div className="w-full">
                          <div className="relative text-gray-400 focus-within:text-gray-600">
                            <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                              <SearchIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </div>
                            <input
                              id="search"
                              className="block w-full bg-white py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white focus:border-white sm:text-sm"
                              placeholder="Search"
                              type="search"
                              name="search"
                              value={searchState}
                              onChange={(val) => {
                                setSearchState(val.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex lg:hidden">
                        {/* Mobile menu button */}
                        <Disclosure.Button className="bg-cyan-600 p-2 rounded-md inline-flex items-center justify-center text-indigo-200 hover:text-white hover:bg-cyan-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-offset-transparent focus:ring-transparent">
                          {open ? (
                            <XIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <MenuIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </Disclosure.Button>
                      </div>
                      <div className="hidden lg:block">
                        <Link
                          className="ml-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-none focus:ring-transparent"
                          to={`/add-recipe-modal`}
                          state={{ backgroundLocation: location }}
                        >
                          <PlusSmIcon
                            className="-ml-1 mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                          Add a Recipe
                        </Link>
                      </div>

                      <div className="hidden lg:block lg:ml-4">
                        <div className="flex items-center">
                          {/* Profile dropdown */}
                          <Menu
                            as="div"
                            className="ml-3 relative flex-shrink-0"
                          >
                            <div>
                              <Menu.Button className="bg-cyan-200 flex text-sm items-center text-white focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-offset-transparent focus:ring-transparent">
                                <div className="">
                                  <div className="text-sm font-medium text-gray-700">
                                    {user.fullName}
                                  </div>
                                  <div className="text-xs font-medium text-gray-500">
                                    {user.email}
                                  </div>
                                </div>
                                <ChevronDownIcon
                                  className={classNames(
                                    "text-gray-400",
                                    "ml-2 h-5 w-5 group-hover:text-gray-500"
                                  )}
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block py-2 px-4 text-sm text-gray-700"
                                      )}
                                      onClick={async () => {
                                        await onLogout(user);
                                      }}
                                    >
                                      Log Out
                                    </button>
                                  )}
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Disclosure.Panel className="lg:hidden">
                    <div className="px-2 pt-2 pb-3 space-y-1 md:flex md:justify-center">
                      <Link
                        className="inline-flex items-center px-4 py-2 w-full md:w-1/2 justify-center border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-indigo-700 focus:outline-none focus:ring-none focus:ring-transparent"
                        to={`/add-recipe-modal`}
                        state={{ backgroundLocation: location }}
                      >
                        <PlusSmIcon
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        Add a Recipe
                      </Link>
                    </div>
                    <div className="pt-4 pb-3 border-t border-indigo-700">
                      <div className="px-5 flex items-center">
                        <div className="ml-3">
                          <div className="text-base font-medium text-gray-700">
                            {user.fullName}
                          </div>
                          <div className="text-sm font-medium text-gray-500">
                            {user.email}
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 px-2 space-y-1">
                        <Disclosure.Button
                          className="block rounded-md py-2 px-3 text-base font-medium text-gray-700 hover:bg-indigo-500 hover:bg-opacity-75"
                          onClick={async () => {
                            await onLogout(user);
                          }}
                        >
                          Log Out
                        </Disclosure.Button>
                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <main className="flex-1">
              <div className="py-6">
                <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
                  <CustomHits />
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    </InstantSearch>
  );
}

export default Home;
