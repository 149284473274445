import { createApi } from "@reduxjs/toolkit/query/react";
import { Cookies } from "react-cookie";
import axios from "axios";

const cookies = new Cookies();

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, body }) => {
    try {
      let headers = {};

      const sessionID = cookies.get("session-id");

      if (typeof sessionID !== "undefined") {
        headers = { "session-id": sessionID };
      }

      const result = await axios({
        method: method,
        url: baseUrl + url,
        data: body,
        headers: headers,
      });

      if (result.status === 200) {
        return { data: result.data };
      }
    } catch (axiosError) {
      let err = axiosError;
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

export const baseAPI = createApi({
  reducerPath: "baseAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = cookies.get("session-id");

      if (token) {
        headers.set("session-id", `${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["User", "Recipe", "Group"],
  endpoints: () => ({}),
});
