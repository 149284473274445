import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import Lottie from "react-lottie";

import { useCreateRecipeFromURLMutation } from "api/recipeAPI";

import * as animationData from "../auth/cookingLoading.json";

const defaultAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function AddRecipeModal() {
  const user = useSelector((state) => state.user);

  let navigate = useNavigate();

  const [open] = useState(true);

  const [loading] = useState(false);

  const [url, setURL] = useState("");

  const [createRecipeFromURL, { isLoading: isCreatingRecipeFromURL }] =
    useCreateRecipeFromURLMutation();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          navigate(-1);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {loading || isCreatingRecipeFromURL ? (
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                  <Lottie
                    options={defaultAnimationOptions}
                    height={"50%"}
                    width={"50%"}
                    isClickToPauseDisabled={true}
                  />
                  <h2 className="mt-2 text-center text-lg font-extralight text-black">
                    Adding Recipe...
                  </h2>
                </Dialog.Panel>
              ) : (
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                  <div>
                    <div className="text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Add a Recipe!
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Use the input below to add any recipe! Enter a URL and
                          click "Go". If you need to enter a recipe manually,
                          please use the Manual Entry Button.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <div>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="company-website"
                          id="company-website"
                          className="focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="www.example.com"
                          value={url}
                          onChange={(e) => setURL(e.target.value)}
                        />
                      </div>
                      <button
                        type="button"
                        className="inline-flex w-full text-center justify-center items-center mt-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-transparent"
                        disabled={url === "" ? true : false}
                        onClick={async () => {
                          if (url !== "") {
                            const recipeRes = await createRecipeFromURL({
                              url: url,
                              groupID: user.groupID,
                            });

                            navigate(`/recipe/${recipeRes.data.recipeID}`);
                          }
                        }}
                      >
                        Go
                      </button>
                      <div className="relative">
                        <div
                          className="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center my-4">
                          <span className="px-2 bg-white text-sm text-gray-500">
                            or
                          </span>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="inline-flex w-full text-center justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-transparent"
                        onClick={async () => {
                          navigate(`/manual-add-recipe`);
                        }}
                      >
                        Manual Entry
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              )}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
