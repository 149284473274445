import { Routes, Route, useLocation } from "react-router-dom";
import { AuthProvider } from "auth/AuthProvider";
import { ProtectedRoute } from "auth/ProtectedRoute";
import Home from "routes/Home";
import Recipe from "routes/Recipe";
import Login from "routes/Login";
import ForgotPassword from "routes/ForgotPassword";
import NotFound from "routes/NotFound";
import AddRecipeModal from "routes/AddRecipeModal";
import ManualAddRecipe from "routes/ManualAddRecipe";

function App() {
  let location = useLocation();

  let state = location.state;

  return (
    <AuthProvider>
      <Routes location={state?.backgroundLocation || location}>
        <Route
          index
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="recipe"
          element={
            <ProtectedRoute>
              <Recipe />
            </ProtectedRoute>
          }
        >
          <Route
            path=":recipeId"
            element={
              <ProtectedRoute>
                <Recipe />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path="manual-add-recipe"
          element={
            <ProtectedRoute>
              <ManualAddRecipe />
            </ProtectedRoute>
          }
        />

        <Route path="login" element={<Login />} />
        <Route path="reset-password" element={<ForgotPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      {state?.backgroundLocation && (
        <Routes>
          <Route path="/add-recipe-modal" element={<AddRecipeModal />} />
        </Routes>
      )}
    </AuthProvider>
  );
}

export default App;
