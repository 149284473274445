import axios from "axios";

import { baseAPI } from "./baseAPI";

const recipeAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    createRecipeFromURL: builder.mutation({
      query: (body) => {
        return {
          url: `/recipe`,
          method: "POST",
          body: body,
        };
      },
    }),
    requestSignedURL: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        console.log("🚀 ~ queryFn: ~ arg", arg);

        const signedURL = await baseQuery({
          url: `/request-signed-url`,
          method: "POST",
          body: {
            fileName: arg.fileName,
            action: "putObject",
            type: arg.type,
          },
        });

        const options = {
          headers: {
            "Content-Type": arg.type,
          },
        };

        const signedRequest = signedURL.data.data.signedRequest;

        const itemURL = signedURL.data.data.url;

        const uploadResults = await axios.put(signedRequest, arg.file, options);

        if (uploadResults.statusText === "OK") {
          let fileObj = {
            results: uploadResults,
            url: itemURL,
            taxYear: arg.taxYear,
          };

          return { data: fileObj };
        }
      },
      // query: (body) => {
      //   return {
      //     url: `/request-signed-url`,
      //     method: "POST",
      //     body: body,
      //   };
      // },
    }),
    createRecipeFromManual: builder.mutation({
      query: (body) => {
        console.log("🚀 ~ body", body);
        return {
          url: `/recipe-manual`,
          method: "POST",
          body: body,
        };
      },
    }),
    getRecipe: builder.query({
      query: (recipeID) => ({
        url: `/recipe/${recipeID}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Recipe", id }],
    }),
    updateRecipe: builder.mutation({
      query: ({ recipeID, body }) => {
        return {
          url: `/recipe/${recipeID}`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: "Recipe", id }],
    }),
    deleteRecipe: builder.mutation({
      query: (recipeID) => {
        return {
          url: `/recipe/${recipeID}`,
          method: "DELETE",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateRecipeFromManualMutation,
  useCreateRecipeFromURLMutation,
  useDeleteRecipeMutation,
  useGetRecipeQuery,
  useUpdateRecipeMutation,
  useRequestSignedURLMutation,
} = recipeAPI;
