import React, { useEffect, useState } from "react";
import _ from "lodash";
import classNames from "classnames";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";

import { useAuth } from "auth/useAuth";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("You must enter a email"),
});

const codeSchema = yup.object().shape({
  code: yup.string().required("Please enter your registration code."),
  password: yup.string().required("Please enter your password."),
});

const defaultValues = {
  email: "",
};

const codeDefaultValues = {
  code: "",
  password: "",
};

export default function ForgotPassword() {
  const { resetPassword, confirmResetPassword } = useAuth();

  const [codeSent, setCodeSent] = useState(false);

  const [generatedID, setGeneratedID] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);

  const { control, formState, handleSubmit, setError } = useForm(
    codeSent === false
      ? {
          mode: "onSubmit",
          defaultValues,
          resolver: yupResolver(schema),
        }
      : {
          mode: "onSubmit",
          codeDefaultValues,
          resolver: yupResolver(codeSchema),
        }
  );

  const { dirtyFields, errors } = formState;

  useEffect(() => {}, []);

  async function onSubmit(model) {
    setLoading(true);

    try {
      const resetData = await resetPassword(model);

      if (resetData.status === true && resetData.codeSent === true) {
        setGeneratedID(resetData.generatedID);
        setCodeSent(resetData.codeSent);
        setLoading(false);

        return;
      }
    } catch (e) {
      console.log("🚀 ~ onSubmit ~ e", e);

      setError("code", {
        type: "manual",
        message: e.data.error,
      });
    }
  }

  async function onSubmitCode(model) {
    setLoading(true);

    try {
      await confirmResetPassword(model, generatedID);

      setLoading(false);
    } catch (e) {
      console.log("🚀 ~ onSubmit ~ e", e);

      setError("confirm", {
        type: "manual",
        message: e.data.error,
      });
    }
  }

  return (
    <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-cyan-200">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {codeSent === false
            ? "Enter your email to reset your password"
            : "Enter your password reset code and new password."}
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        {loading ? (
          <div className="flex justify-center">
            <svg
              className="animate-spin -ml-1 mr-3 h-16 w-16 text-red-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        ) : (
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {codeSent === false ? (
              <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          {...field}
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  )}
                />

                {_.isEmpty(errors) ? null : (
                  <div className="rounded-md bg-red-50 p-4 mt-3 mb-3">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <svg
                          className="h-5 w-5 text-red-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm leading-5 font-medium text-red-800">
                          There was an error with your submission
                        </h3>
                        <div className="mt-2 text-sm leading-5 text-red-700">
                          <ul className="list-disc pl-5">
                            {errors.email && <p>{errors.email.message}</p>}
                            {errors.code && <p>{errors.code.message}</p>}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div>
                  {loading ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    <button
                      type="submit"
                      className={classNames(
                        "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none",
                        _.isEmpty(dirtyFields) &&
                          "bg-gray-400 opacity-50 cursor-not-allowed",
                        loading && "bg-gray-400 opacity-50 cursor-not-allowed"
                      )}
                    >
                      Submit Password Reset
                    </button>
                  )}
                </div>
              </form>
            ) : (
              <form className="space-y-6" onSubmit={handleSubmit(onSubmitCode)}>
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => (
                    <div>
                      <label
                        htmlFor="code"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password Reset Code
                      </label>
                      <div className="mt-1">
                        <input
                          {...field}
                          id="code"
                          name="code"
                          type="text"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  )}
                />

                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          {...field}
                          id="password"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          autoComplete="current-password"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />

                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                          <button
                            type="button"
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                          >
                            {showPassword ? (
                              <EyeOffIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            ) : (
                              <EyeIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                />

                {_.isEmpty(errors) ? null : (
                  <div className="rounded-md bg-red-50 p-4 mt-3 mb-3">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <svg
                          className="h-5 w-5 text-red-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm leading-5 font-medium text-red-800">
                          There was an error with your submission
                        </h3>
                        <div className="mt-2 text-sm leading-5 text-red-700">
                          <ul className="list-disc pl-5">
                            {errors.password && (
                              <p>{errors.password.message}</p>
                            )}
                            {errors.confirm && <p>{errors.confirm.message}</p>}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div>
                  {loading ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    <button
                      type="submit"
                      className={classNames(
                        "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none",
                        _.isEmpty(dirtyFields) &&
                          "bg-gray-400 opacity-50 cursor-not-allowed",
                        loading && "bg-gray-400 opacity-50 cursor-not-allowed"
                      )}
                    >
                      Complete Password Reset
                    </button>
                  )}
                </div>
              </form>
            )}

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Or</span>
                </div>
              </div>

              <div className="mt-6 ">
                <div>
                  <button
                    type="button"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-400 hover:bg-blue-500 focus:outline-none"
                    // onClick={() => props.history.push(`/login`)}
                  >
                    Sign In
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
