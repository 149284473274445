import { baseAPI } from "./baseAPI";

const userAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.mutation({
      query: (userID) => {
        return {
          url: `/users/${userID}`,
          method: "GET",
        };
      },
      providesTags: (result, error, id) => [{ type: "User", id }],
    }),
    updateUser: builder.mutation({
      query: ({ userID, body }) => {
        return {
          url: `/updateUser/${userID}`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: "User", id }],
    }),
    deleteUser: builder.mutation({
      query: (userID) => {
        return {
          url: `/users/${userID}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: "User", id }],
    }),
    inviteUser: builder.mutation({
      query: (body) => {
        return {
          url: `/invite-user`,
          method: "POST",
          body: body,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserMutation,
  useUpdateUserMutation,
  useInviteUserMutation,
  useDeleteUserMutation,
} = userAPI;
