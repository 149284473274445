import { Link } from "react-router-dom";

import { ClockIcon, ScaleIcon } from "@heroicons/react/solid";

export default function RecipeCard(props) {
  return (
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
      {props.recipes.map((recipe) => (
        <li
          key={recipe.objectID}
          className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow-lg border divide-y divide-gray-200"
        >
          <Link
            className={
              "h-full w-full focus:outline-none hover:bg-gray-200 rounded-lg"
            }
            to={`/recipe/${recipe.objectID}`}
          >
            <div className="flex-1 flex flex-col p-8">
              <img
                className="w-40 h-40 flex-shrink-0 mx-auto rounded-md"
                src={recipe.imageUrls[0]}
                alt=""
              />
              <h3 className="mt-6 text-gray-900 text-sm font-medium">
                {recipe.recipeName}
              </h3>
              <dl className="mt-1 flex-grow flex flex-col justify-between">
                <dd className="text-gray-500 text-sm">
                  {/* {recipe.categories[0]} */}
                </dd>
              </dl>
            </div>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className="w-0 flex-1 flex">
                  <div className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg">
                    <ClockIcon
                      className="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">
                      {Math.floor(recipe.cookTime / 60000000)} Minutes
                    </span>
                  </div>
                </div>
                <div className="-ml-px w-0 flex-1 flex">
                  <div className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg">
                    <ScaleIcon
                      className="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">{recipe.servings} Servings</span>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  );
}
