import { useParams, useLocation, useNavigate, Link } from "react-router-dom";

import { Fragment, useState } from "react";
import { useSelector } from "react-redux";

import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon, ChevronDownIcon } from "@heroicons/react/outline";
import {
  PlusSmIcon,
  ClockIcon,
  ScaleIcon,
  PencilIcon,
  CheckIcon,
  PlusIcon,
  ExclamationIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import {
  useGetRecipeQuery,
  useUpdateRecipeMutation,
  useDeleteRecipeMutation,
} from "api/recipeAPI";

import { useAuth } from "auth/useAuth";

const navigation = [{ name: "Home", href: "/home", current: false }];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Recipe() {
  const { onLogout } = useAuth();

  const user = useSelector((state) => state.user);

  let params = useParams();
  let location = useLocation();
  let navigate = useNavigate();

  const { data: recipeData, isLoading: isGettingRecipeData } =
    useGetRecipeQuery(params.recipeId);

  const [updateRecipe, { isLoading: isUpdatingRecipe }] =
    useUpdateRecipeMutation();

  const [deleteRecipe, { isLoading: isDeletingRecipe }] =
    useDeleteRecipeMutation();

  const [loading, setLoading] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const [editRecipe, setEditRecipe] = useState(null);

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  async function onChangeRecipe(key, value) {
    setEditRecipe({ ...editRecipe, [key]: value });
  }

  async function onChangeRecipeArray(key, value, index) {
    let tmpArr = [...editRecipe[key]];

    tmpArr[index] = value;

    setEditRecipe({ ...editRecipe, [key]: tmpArr });
  }

  async function onDeleteRecipeArrayItem(key, index) {
    let tmpArr = [...editRecipe[key]];

    tmpArr.splice(index, 1);

    setEditRecipe({ ...editRecipe, [key]: tmpArr });
  }

  return (
    <>
      <div className="min-h-full">
        <Disclosure
          as="nav"
          className="bg-cyan-200 border-b border-indigo-300 border-opacity-25 lg:border-none"
        >
          {({ open }) => (
            <>
              <div className="mx-auto px-2 sm:px-4 lg:px-8">
                <div className="relative h-16 flex items-center justify-between lg:border-b lg:border-indigo-400 lg:border-opacity-25">
                  <div className="flex-1 px-2 flex justify-center lg:ml-6 lg:justify-end">
                    <div className="hidden lg:block lg:ml-10">
                      <div className="flex space-x-4">
                        {navigation.map((item) => (
                          <Link
                            key={item.name}
                            className={classNames(
                              item.current
                                ? "bg-indigo-700 text-white"
                                : "text-gray-700 hover:bg-gray-800 hover:text-white hover:bg-opacity-75",
                              "rounded-md py-2 px-3 text-sm font-medium"
                            )}
                            to={item.href}
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                    <div className="w-full"></div>
                  </div>
                  <div className="flex lg:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-cyan-600 p-2 rounded-md inline-flex items-center justify-center text-indigo-200 hover:text-white hover:bg-cyan-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-offset-transparent focus:ring-transparent">
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="hidden lg:block">
                    <Link
                      className="ml-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-none focus:ring-transparent"
                      to={`/add-recipe-modal`}
                      state={{ backgroundLocation: location }}
                    >
                      <PlusSmIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      Add a Recipe
                    </Link>
                  </div>

                  <div className="hidden lg:block lg:ml-4">
                    <div className="flex items-center">
                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-3 relative flex-shrink-0">
                        <div>
                          <Menu.Button className="bg-cyan-200 flex text-sm items-center text-white focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-offset-transparent focus:ring-transparent">
                            <div className="">
                              <div className="text-sm font-medium text-gray-700">
                                {user.fullName}
                              </div>
                              <div className="text-xs font-medium text-gray-500">
                                {user.email}
                              </div>
                            </div>
                            <ChevronDownIcon
                              className={classNames(
                                "text-gray-400",
                                "ml-2 h-5 w-5 group-hover:text-gray-500"
                              )}
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block py-2 px-4 text-sm text-gray-700"
                                  )}
                                  onClick={async () => {
                                    await onLogout(user);
                                  }}
                                >
                                  Log Out
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="lg:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 md:flex md:flex-col md:justify-center md:items-center">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current
                          ? "bg-indigo-700 text-white"
                          : "text-gray-700 hover:bg-indigo-500 hover:bg-opacity-75",
                        "flex justify-center rounded-md py-2 px-3 text-base font-medium"
                      )}
                    >
                      {item.name}
                    </Link>
                  ))}
                  <Link
                    className="inline-flex items-center px-4 py-2 w-full md:w-1/2 justify-center border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-indigo-700 focus:outline-none focus:ring-none focus:ring-transparent"
                    to={`/add-recipe-modal`}
                    state={{ backgroundLocation: location }}
                  >
                    <PlusSmIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Add a Recipe
                  </Link>
                </div>
                <div className="pt-4 pb-3 border-t border-indigo-700">
                  <div className="px-5 flex items-center">
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-700">
                        {user.fullName}
                      </div>
                      <div className="text-sm font-medium text-gray-500">
                        {user.email}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    <Disclosure.Button
                      className="block rounded-md py-2 px-3 text-base font-medium text-gray-700 hover:bg-indigo-500 hover:bg-opacity-75"
                      onClick={async () => {
                        await onLogout(user);
                      }}
                    >
                      Log Out
                    </Disclosure.Button>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <main className="lg:mt-16">
          {isGettingRecipeData ||
          loading ||
          isUpdatingRecipe ||
          isDeletingRecipe ? (
            <div className="mx-auto sm:px-6 lg:px-8 mb-8">
              <div className="bg-white lg:border animate-pulse lg:rounded-lg lg:shadow px-5 py-6 sm:px-6">
                <div className="flex flex-col">
                  <div className="flex w-full">
                    <div className="w-1/3">
                      <div className="h-32 w-32 bg-slate-700 rounded-lg"></div>
                    </div>

                    <div className="flex flex-col w-2/3 lg:ml-16 ml-8">
                      <div className="h-2 bg-slate-700 rounded mt-8"></div>
                      <div className="flex mt-4">
                        <div className="flex items-center mr-2">
                          <ClockIcon
                            className="w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <div className="h-2 w-16 lg:w-32 bg-slate-700 rounded lg:ml-3"></div>
                        </div>
                        <div className="flex items-center ml-2">
                          <ScaleIcon
                            className="w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <div className="h-2 w-16 lg:w-32 bg-slate-700 rounded lg:ml-3"></div>
                        </div>
                      </div>
                      <div className="flex mt-4"></div>
                    </div>
                  </div>

                  <div className="lg:mt-16 mt-8 pb-8">
                    <div className="max-w-3xl mx-auto lg:max-w-7xl">
                      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
                        <div className="grid grid-cols-1 gap-4">
                          <div className="pb-4">
                            <h3 className="text-lg lg:text-2xl text-center leading-6 font-medium lg:font-bold text-gray-900 ">
                              Ingredients
                            </h3>
                          </div>
                          <ul className="divide-y divide-gray-200">
                            <li
                              key={1}
                              className="px-4 py-4 sm:px-0 font-light"
                            >
                              <div className="h-2 w-full bg-slate-700 rounded"></div>
                            </li>
                            <li
                              key={2}
                              className="px-4 py-4 sm:px-0 font-light"
                            >
                              <div className="h-2 w-full bg-slate-700 rounded"></div>
                            </li>
                            <li
                              key={3}
                              className="px-4 py-4 sm:px-0 font-light"
                            >
                              <div className="h-2 w-full bg-slate-700 rounded"></div>
                            </li>
                          </ul>
                        </div>

                        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                          <div className="pb-4">
                            <h3 className="text-lg lg:text-2xl text-center leading-6 font-medium lg:font-bold text-gray-900">
                              Instructions
                            </h3>
                          </div>
                          <ul className="">
                            <li
                              key={1}
                              className="flex items-center px-4 py-4 sm:px-0 font-light"
                            >
                              <div className="h-2 w-full bg-slate-700 rounded lg:ml-3"></div>
                            </li>

                            <li
                              key={2}
                              className="flex items-center px-4 py-4 sm:px-0 font-light"
                            >
                              <div className="h-2 w-full bg-slate-700 rounded lg:ml-3"></div>
                            </li>

                            <li
                              key={3}
                              className="flex items-center px-4 py-4 sm:px-0 font-light"
                            >
                              <div className="h-2 w-full bg-slate-700 rounded lg:ml-3"></div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : isEditing ? (
            <div className="mx-auto sm:px-6 lg:px-8">
              <div className="bg-white lg:rounded-lg lg:border lg:mb-16 lg:shadow px-5 py-6 sm:px-6">
                <div className="flex flex-col">
                  <div className="flex w-full">
                    <div className="w-1/3">
                      <img
                        className="lg:h-32 lg:w-32 rounded-lg"
                        src={editRecipe.imageUrls[0]}
                        alt="Workflow"
                      />
                    </div>

                    <div className="flex flex-col w-2/3 lg:ml-16 ml-8">
                      <div>
                        <input
                          type="text"
                          name="recipeName"
                          id="recipeName"
                          className="shadow-sm text-xs lg:text-3xl font-semibold text-gray-800 tracking-wide w-full border-gray-300 rounded-md"
                          placeholder={editRecipe.recipeName}
                          value={editRecipe.recipeName}
                          onChange={(event) => {
                            onChangeRecipe("recipeName", event.target.value);
                          }}
                        />
                      </div>

                      <div className="flex flex-col lg:flex-row mt-4">
                        <div className="flex items-center md:mr-2">
                          <ClockIcon
                            className="hidden lg:block w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="flex flex-row items-center lg:ml-3">
                            <input
                              type="text"
                              name="cookTime"
                              id="cookTime"
                              className="text-xs md:text-base shadow-sm text-gray-800 border-gray-300 rounded-md"
                              placeholder={Math.floor(
                                editRecipe.cookTime / 60000000
                              )}
                              value={Math.floor(editRecipe.cookTime / 60000000)}
                              onChange={(event) => {
                                onChangeRecipe(
                                  "cookTime",
                                  event.target.value * 60000000
                                );
                              }}
                            />
                            <p className="ml-3">Minutes</p>
                          </span>
                        </div>
                        <div className="flex items-center lg:ml-2">
                          <ScaleIcon
                            className="hidden lg:block w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="flex flex-row items-center mt-4 lg:mt-0 lg:ml-3">
                            <input
                              type="text"
                              name="servings"
                              id="servings"
                              className="text-xs md:text-base shadow-sm text-gray-800 border-gray-300 rounded-md"
                              placeholder={editRecipe.servings}
                              value={editRecipe.servings}
                              onChange={(event) => {
                                onChangeRecipe(
                                  "servings",
                                  Number(event.target.value)
                                );
                              }}
                            />
                            <p className="ml-3">Servings</p>
                          </span>
                        </div>
                      </div>
                      <div className="flex mt-4">
                        <button
                          type="button"
                          className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-none focus:ring-offset-none focus:ring-offset-none focus:ring-none"
                          onClick={async () => {
                            const res = await updateRecipe({
                              recipeID: editRecipe.recipeID,
                              body: {
                                recipeName: editRecipe.recipeName || "",
                                recipeAuthor: "",
                                cookTime: editRecipe.cookTime || 0,
                                categories: editRecipe.categories || "",
                                cuisines: editRecipe.cuisines || "",
                                ingredients: editRecipe.ingredients || [],
                                instructions: editRecipe.instructions || [],
                                servings: editRecipe.servings || 0,
                              },
                            });

                            if (res.data.status === true) {
                              setEditRecipe(null);

                              setIsEditing(false);
                            }
                          }}
                        >
                          <CheckIcon
                            className="-ml-1 mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                          <span>Save Edits!</span>
                        </button>
                        {deleteConfirm ? (
                          <button
                            type="button"
                            className="relative inline-flex items-center ml-8 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-none focus:ring-offset-none focus:ring-offset-none focus:ring-none"
                            onClick={async () => {
                              setLoading(true);

                              const res = await deleteRecipe(
                                editRecipe.recipeID
                              );

                              if (res.data.status === true) {
                                setInterval(() => {
                                  setEditRecipe(null);

                                  setIsEditing(false);

                                  navigate("/home");

                                  window.location.reload(false);
                                }, 3000);
                              }
                            }}
                          >
                            <XIcon
                              className="-ml-1 mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                            <span>Confirm Delete</span>
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="relative inline-flex items-center ml-8 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-none focus:ring-offset-none focus:ring-offset-none focus:ring-none"
                            onClick={async () => {
                              setDeleteConfirm(true);
                            }}
                          >
                            <ExclamationIcon
                              className="-ml-1 mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                            <span>Delete Recipe?</span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="lg:mt-16 mt-8 pb-8">
                    <div className="max-w-3xl mx-auto lg:max-w-7xl">
                      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
                        <div className="grid grid-cols-1 gap-4">
                          <div className="pb-4">
                            <h3 className="text-lg lg:text-2xl text-center leading-6 font-medium lg:font-bold text-gray-900 ">
                              Ingredients
                            </h3>
                          </div>
                          <ul className="divide-y divide-gray-200">
                            {editRecipe.ingredients.map((item, index) => (
                              <li
                                key={index}
                                className="flex flex-row px-4 py-4 sm:px-0 font-light"
                              >
                                <input
                                  type="text"
                                  name="ingredients"
                                  id="ingredients"
                                  className="shadow-sm text-gray-800 border-gray-300 rounded-md w-full"
                                  placeholder={"Add an ingredient!"}
                                  value={item.name}
                                  onChange={(event) => {
                                    let val = {
                                      name: event.target.value,
                                      type: "default",
                                    };

                                    onChangeRecipeArray(
                                      "ingredients",
                                      val,
                                      index
                                    );
                                  }}
                                />
                                <button
                                  onClick={() => {
                                    onDeleteRecipeArrayItem(
                                      "ingredients",
                                      index
                                    );
                                  }}
                                >
                                  <TrashIcon
                                    className=" ml-2 h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </li>
                            ))}
                          </ul>
                          <div className="flex flex-row">
                            <button
                              type="button"
                              className="inline-flex items-center w-full justify-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-transparent"
                              onClick={() => {
                                let val = {
                                  name: "",
                                  type: "default",
                                };

                                let tmpArr = [...editRecipe.ingredients];

                                tmpArr.push(val);

                                setEditRecipe({
                                  ...editRecipe,
                                  ingredients: tmpArr,
                                });
                              }}
                            >
                              <PlusIcon
                                className="-ml-0.5 mr-2 h-4 w-4"
                                aria-hidden="true"
                              />
                              Add an ingredient!
                            </button>
                          </div>
                        </div>

                        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                          <div className="pb-4">
                            <h3 className="text-lg lg:text-2xl text-center leading-6 font-medium lg:font-bold text-gray-900">
                              Instructions
                            </h3>
                          </div>
                          <ul className="">
                            {editRecipe.instructions.map((item, index) => (
                              <li
                                key={index}
                                className="flex flex-row items-center px-4 py-4 sm:px-0 font-light"
                              >
                                <div className="flex mr-8 ml-4 items-baseline lg:text-lg font-semibold text-red-600">
                                  {index + 1}
                                </div>
                                <textarea
                                  rows={4}
                                  name="instruction"
                                  id="instruction"
                                  type="text"
                                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                  placeholder={"Add a Step!"}
                                  defaultValue={item.text}
                                  onChange={(event) => {
                                    let val = {
                                      name:
                                        event.target.value?.length > 44
                                          ? event.target.value.substr(
                                              0,
                                              44 - 1
                                            ) + "..."
                                          : event.target.value,
                                      text: event.target.value,
                                      type: "step",
                                    };

                                    onChangeRecipeArray(
                                      "instructions",
                                      val,
                                      index
                                    );
                                  }}
                                />
                                <button
                                  onClick={() => {
                                    onDeleteRecipeArrayItem(
                                      "instructions",
                                      index
                                    );
                                  }}
                                >
                                  <TrashIcon
                                    className=" ml-2 h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </li>
                            ))}
                          </ul>
                          <div className="flex flex-row">
                            <button
                              type="button"
                              className="inline-flex items-center w-full ml-16 justify-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-transparent"
                              onClick={() => {
                                let val = {
                                  name: "",
                                  text: "",
                                  type: "step",
                                };

                                let tmpArr = [...editRecipe.instructions];

                                tmpArr.push(val);

                                setEditRecipe({
                                  ...editRecipe,
                                  instructions: tmpArr,
                                });
                              }}
                            >
                              <PlusIcon
                                className="-ml-0.5 mr-2 h-4 w-4"
                                aria-hidden="true"
                              />
                              Add an ingredient!
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mx-auto sm:px-6 lg:px-8">
              <div className="bg-white lg:rounded-lg lg:border lg:mb-16 lg:shadow px-5 py-6 sm:px-6">
                <div className="flex flex-col">
                  <div className="flex w-full">
                    <div className="w-1/3">
                      <img
                        className="lg:h-32 lg:w-32 rounded-lg"
                        src={recipeData.imageUrls[0]}
                        alt="Workflow"
                      />
                    </div>

                    <div className="flex flex-col w-2/3 lg:ml-16 ml-8">
                      <h1 className="lg:text-3xl sm:text-base font-semibold text-gray-800 tracking-wide">
                        {recipeData.recipeName}
                      </h1>
                      <div className="flex mt-4">
                        <div className="flex items-center mr-2">
                          <ClockIcon
                            className="w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="lg:ml-3">
                            {Math.floor(recipeData.cookTime / 60000000)} Minutes
                          </span>
                        </div>
                        <div className="flex items-center ml-2">
                          <ScaleIcon
                            className="w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="lg:ml-3">
                            {recipeData.servings} Servings
                          </span>
                        </div>
                      </div>
                      <div className="flex mt-4">
                        <button
                          type="button"
                          className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-none focus:ring-offset-none focus:ring-offset-none focus:ring-none"
                          onClick={() => {
                            setEditRecipe(recipeData);
                            setIsEditing(true);
                          }}
                        >
                          <PencilIcon
                            className="-ml-1 mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                          <span>Edit Recipe</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="lg:mt-16 mt-8 pb-8">
                    <div className="max-w-3xl mx-auto lg:max-w-7xl">
                      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
                        <div className="grid grid-cols-1 gap-4">
                          <div className="pb-4">
                            <h3 className="text-lg lg:text-2xl text-center leading-6 font-medium lg:font-bold text-gray-900 ">
                              Ingredients
                            </h3>
                          </div>
                          <ul className="divide-y divide-gray-200">
                            {recipeData.ingredients.map((item, index) => (
                              <li
                                key={index}
                                className="px-4 py-4 sm:px-0 font-light"
                              >
                                {item.name}
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                          <div className="pb-4">
                            <h3 className="text-lg lg:text-2xl text-center leading-6 font-medium lg:font-bold text-gray-900">
                              Instructions
                            </h3>
                          </div>
                          <ul className="">
                            {recipeData.instructions.map((item, index) => (
                              <li
                                key={index}
                                className="flex flex-row items-center px-4 py-4 sm:px-0 font-light"
                              >
                                <div className="flex mr-8 ml-4 items-baseline lg:text-lg font-semibold text-red-600">
                                  {index + 1}
                                </div>
                                {item.text}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  );
}
